export const ICONS_DATA = [
	"address-book",
	"air-traffic-control",
	"airplane",
	"airplane-in-flight",
	"airplane-landing",
	"airplane-takeoff",
	"airplane-tilt",
	"airplay",
	"alarm",
	"alien",
	"align-bottom",
	"align-bottom-simple",
	"align-center-horizontal",
	"align-center-horizontal-simple",
	"align-center-vertical",
	"align-center-vertical-simple",
	"align-left",
	"align-left-simple",
	"align-right",
	"align-right-simple",
	"align-top",
	"align-top-simple",
	"amazon-logo",
	"anchor",
	"anchor-simple",
	"android-logo",
	"angular-logo",
	"aperture",
	"app-store-logo",
	"app-window",
	"apple-logo",
	"apple-podcasts-logo",
	"archive",
	"archive-box",
	"archive-tray",
	"armchair",
	"arrow-arc-left",
	"arrow-arc-right",
	"arrow-bend-double-up-left",
	"arrow-bend-double-up-right",
	"arrow-bend-down-left",
	"arrow-bend-down-right",
	"arrow-bend-left-down",
	"arrow-bend-left-up",
	"arrow-bend-right-down",
	"arrow-bend-right-up",
	"arrow-bend-up-left",
	"arrow-bend-up-right",
	"arrow-circle-down",
	"arrow-circle-down-left",
	"arrow-circle-down-right",
	"arrow-circle-left",
	"arrow-circle-right",
	"arrow-circle-up",
	"arrow-circle-up-left",
	"arrow-circle-up-right",
	"arrow-clockwise",
	"arrow-counter-clockwise",
	"arrow-down",
	"arrow-down-left",
	"arrow-down-right",
	"arrow-elbow-down-left",
	"arrow-elbow-down-right",
	"arrow-elbow-left",
	"arrow-elbow-left-down",
	"arrow-elbow-left-up",
	"arrow-elbow-right",
	"arrow-elbow-right-down",
	"arrow-elbow-right-up",
	"arrow-elbow-up-left",
	"arrow-elbow-up-right",
	"arrow-fat-down",
	"arrow-fat-left",
	"arrow-fat-line-down",
	"arrow-fat-line-left",
	"arrow-fat-line-right",
	"arrow-fat-line-up",
	"arrow-fat-lines-down",
	"arrow-fat-lines-left",
	"arrow-fat-lines-right",
	"arrow-fat-lines-up",
	"arrow-fat-right",
	"arrow-fat-up",
	"arrow-left",
	"arrow-line-down",
	"arrow-line-down-left",
	"arrow-line-down-right",
	"arrow-line-left",
	"arrow-line-right",
	"arrow-line-up",
	"arrow-line-up-left",
	"arrow-line-up-right",
	"arrow-right",
	"arrow-square-down",
	"arrow-square-down-left",
	"arrow-square-down-right",
	"arrow-square-in",
	"arrow-square-left",
	"arrow-square-out",
	"arrow-square-right",
	"arrow-square-up",
	"arrow-square-up-left",
	"arrow-square-up-right",
	"arrow-u-down-left",
	"arrow-u-down-right",
	"arrow-u-left-down",
	"arrow-u-left-up",
	"arrow-u-right-down",
	"arrow-u-right-up",
	"arrow-u-up-left",
	"arrow-u-up-right",
	"arrow-up",
	"arrow-up-left",
	"arrow-up-right",
	"arrows-clockwise",
	"arrows-counter-clockwise",
	"arrows-down-up",
	"arrows-horizontal",
	"arrows-in",
	"arrows-in-cardinal",
	"arrows-in-line-horizontal",
	"arrows-in-line-vertical",
	"arrows-in-simple",
	"arrows-left-right",
	"arrows-merge",
	"arrows-out",
	"arrows-out-cardinal",
	"arrows-out-line-horizontal",
	"arrows-out-line-vertical",
	"arrows-out-simple",
	"arrows-split",
	"arrows-vertical",
	"article",
	"article-medium",
	"article-ny-times",
	"asterisk",
	"asterisk-simple",
	"at",
	"atom",
	"baby",
	"backpack",
	"backspace",
	"bag",
	"bag-simple",
	"balloon",
	"bandaids",
	"bank",
	"barbell",
	"barcode",
	"barricade",
	"baseball",
	"baseball-cap",
	"basket",
	"basketball",
	"bathtub",
	"battery-charging",
	"battery-charging-vertical",
	"battery-empty",
	"battery-full",
	"battery-high",
	"battery-low",
	"battery-medium",
	"battery-plus",
	"battery-plus-vertical",
	"battery-vertical-empty",
	"battery-vertical-full",
	"battery-vertical-high",
	"battery-vertical-low",
	"battery-vertical-medium",
	"battery-warning",
	"battery-warning-vertical",
	"bed",
	"beer-bottle",
	"beer-stein",
	"behance-logo",
	"bell",
	"bell-ringing",
	"bell-simple",
	"bell-simple-ringing",
	"bell-simple-slash",
	"bell-simple-z",
	"bell-slash",
	"bell-z",
	"bezier-curve",
	"bicycle",
	"binoculars",
	"bird",
	"bluetooth",
	"bluetooth-connected",
	"bluetooth-slash",
	"bluetooth-x",
	"boat",
	"bone",
	"book",
	"book-bookmark",
	"book-open",
	"book-open-text",
	"bookmark",
	"bookmark-simple",
	"bookmarks",
	"bookmarks-simple",
	"books",
	"boot",
	"bounding-box",
	"bowl-food",
	"brackets-angle",
	"brackets-curly",
	"brackets-round",
	"brackets-square",
	"brain",
	"brandy",
	"bridge",
	"briefcase",
	"briefcase-metal",
	"broadcast",
	"broom",
	"browser",
	"browsers",
	"bug",
	"bug-beetle",
	"bug-droid",
	"buildings",
	"bus",
	"butterfly",
	"cactus",
	"cake",
	"calculator",
	"calendar",
	"calendar-blank",
	"calendar-check",
	"calendar-plus",
	"calendar-x",
	"call-bell",
	"camera",
	"camera-plus",
	"camera-rotate",
	"camera-slash",
	"campfire",
	"car",
	"car-profile",
	"car-simple",
	"cardholder",
	"cards",
	"caret-circle-double-down",
	"caret-circle-double-left",
	"caret-circle-double-right",
	"caret-circle-double-up",
	"caret-circle-down",
	"caret-circle-left",
	"caret-circle-right",
	"caret-circle-up",
	"caret-circle-up-down",
	"caret-double-down",
	"caret-double-left",
	"caret-double-right",
	"caret-double-up",
	"caret-down",
	"caret-left",
	"caret-right",
	"caret-up",
	"caret-up-down",
	"carrot",
	"cassette-tape",
	"castle-turret",
	"cat",
	"cell-signal-full",
	"cell-signal-high",
	"cell-signal-low",
	"cell-signal-medium",
	"cell-signal-none",
	"cell-signal-slash",
	"cell-signal-x",
	"certificate",
	"chair",
	"chalkboard",
	"chalkboard-simple",
	"chalkboard-teacher",
	"champagne",
	"charging-station",
	"chart-bar",
	"chart-bar-horizontal",
	"chart-donut",
	"chart-line",
	"chart-line-down",
	"chart-line-up",
	"chart-pie",
	"chart-pie-slice",
	"chart-polar",
	"chart-scatter",
	"chat",
	"chat-centered",
	"chat-centered-dots",
	"chat-centered-text",
	"chat-circle",
	"chat-circle-dots",
	"chat-circle-text",
	"chat-dots",
	"chat-teardrop",
	"chat-teardrop-dots",
	"chat-teardrop-text",
	"chat-text",
	"chats",
	"chats-circle",
	"chats-teardrop",
	"check",
	"check-circle",
	"check-fat",
	"check-square",
	"check-square-offset",
	"checks",
	"church",
	"circle",
	"circle-dashed",
	"circle-half",
	"circle-half-tilt",
	"circle-notch",
	"circles-four",
	"circles-three",
	"circles-three-plus",
	"circuitry",
	"clipboard",
	"clipboard-text",
	"clock",
	"clock-afternoon",
	"clock-clockwise",
	"clock-countdown",
	"clock-counter-clockwise",
	"closed-captioning",
	"cloud",
	"cloud-arrow-down",
	"cloud-arrow-up",
	"cloud-check",
	"cloud-fog",
	"cloud-lightning",
	"cloud-moon",
	"cloud-rain",
	"cloud-slash",
	"cloud-snow",
	"cloud-sun",
	"cloud-warning",
	"cloud-x",
	"club",
	"coat-hanger",
	"coda-logo",
	"code",
	"code-block",
	"code-simple",
	"codepen-logo",
	"codesandbox-logo",
	"coffee",
	"coin",
	"coin-vertical",
	"coins",
	"columns",
	"command",
	"compass",
	"compass-tool",
	"computer-tower",
	"confetti",
	"contactless-payment",
	"control",
	"cookie",
	"cooking-pot",
	"copy",
	"copy-simple",
	"copyleft",
	"copyright",
	"corners-in",
	"corners-out",
	"couch",
	"cpu",
	"credit-card",
	"crop",
	"cross",
	"crosshair",
	"crosshair-simple",
	"crown",
	"crown-simple",
	"cube",
	"cube-focus",
	"cube-transparent",
	"currency-btc",
	"currency-circle-dollar",
	"currency-cny",
	"currency-dollar",
	"currency-dollar-simple",
	"currency-eth",
	"currency-eur",
	"currency-gbp",
	"currency-inr",
	"currency-jpy",
	"currency-krw",
	"currency-kzt",
	"currency-ngn",
	"currency-rub",
	"cursor",
	"cursor-click",
	"cursor-text",
	"cylinder",
	"database",
	"desktop",
	"desktop-tower",
	"detective",
	"dev-to-logo",
	"device-mobile",
	"device-mobile-camera",
	"device-mobile-speaker",
	"device-tablet",
	"device-tablet-camera",
	"device-tablet-speaker",
	"devices",
	"diamond",
	"diamonds-four",
	"dice-five",
	"dice-four",
	"dice-one",
	"dice-six",
	"dice-three",
	"dice-two",
	"disc",
	"discord-logo",
	"divide",
	"dna",
	"dog",
	"door",
	"door-open",
	"dot",
	"dot-outline",
	"dots-nine",
	"dots-six",
	"dots-six-vertical",
	"dots-three",
	"dots-three-circle",
	"dots-three-circle-vertical",
	"dots-three-outline",
	"dots-three-outline-vertical",
	"dots-three-vertical",
	"download",
	"download-simple",
	"dress",
	"dribbble-logo",
	"drop",
	"drop-half",
	"drop-half-bottom",
	"dropbox-logo",
	"ear",
	"ear-slash",
	"egg",
	"egg-crack",
	"eject",
	"eject-simple",
	"elevator",
	"engine",
	"envelope",
	"envelope-open",
	"envelope-simple",
	"envelope-simple-open",
	"equalizer",
	"equals",
	"eraser",
	"escalator-down",
	"escalator-up",
	"exam",
	"exclude",
	"exclude-square",
	"export",
	"eye",
	"eye-closed",
	"eye-slash",
	"eyedropper",
	"eyedropper-sample",
	"eyeglasses",
	"face-mask",
	"facebook-logo",
	"factory",
	"faders",
	"faders-horizontal",
	"fan",
	"fast-forward",
	"fast-forward-circle",
	"feather",
	"figma-logo",
	"file",
	"file-archive",
	"file-arrow-down",
	"file-arrow-up",
	"file-audio",
	"file-cloud",
	"file-code",
	"file-css",
	"file-csv",
	"file-dashed",
	"file-doc",
	"file-html",
	"file-image",
	"file-jpg",
	"file-js",
	"file-jsx",
	"file-lock",
	"file-minus",
	"file-pdf",
	"file-plus",
	"file-png",
	"file-ppt",
	"file-rs",
	"file-search",
	"file-sql",
	"file-svg",
	"file-text",
	"file-ts",
	"file-tsx",
	"file-video",
	"file-vue",
	"file-x",
	"file-xls",
	"file-zip",
	"files",
	"film-reel",
	"film-script",
	"film-slate",
	"film-strip",
	"fingerprint",
	"fingerprint-simple",
	"finn-the-human",
	"fire",
	"fire-extinguisher",
	"fire-simple",
	"first-aid",
	"first-aid-kit",
	"fish",
	"fish-simple",
	"flag",
	"flag-banner",
	"flag-checkered",
	"flag-pennant",
	"flame",
	"flashlight",
	"flask",
	"floppy-disk",
	"floppy-disk-back",
	"flow-arrow",
	"flower",
	"flower-lotus",
	"flower-tulip",
	"flying-saucer",
	"folder",
	"folder-dashed",
	"folder-lock",
	"folder-minus",
	"folder-notch",
	"folder-notch-minus",
	"folder-notch-open",
	"folder-notch-plus",
	"folder-open",
	"folder-plus",
	"folder-simple",
	"folder-simple-dashed",
	"folder-simple-lock",
	"folder-simple-minus",
	"folder-simple-plus",
	"folder-simple-star",
	"folder-simple-user",
	"folder-star",
	"folder-user",
	"folders",
	"football",
	"footprints",
	"fork-knife",
	"frame-corners",
	"framer-logo",
	"function",
	"funnel",
	"funnel-simple",
	"game-controller",
	"garage",
	"gas-can",
	"gas-pump",
	"gauge",
	"gavel",
	"gear",
	"gear-fine",
	"gear-six",
	"gender-female",
	"gender-intersex",
	"gender-male",
	"gender-neuter",
	"gender-nonbinary",
	"gender-transgender",
	"ghost",
	"gif",
	"gift",
	"git-branch",
	"git-commit",
	"git-diff",
	"git-fork",
	"git-merge",
	"git-pull-request",
	"github-logo",
	"gitlab-logo",
	"gitlab-logo-simple",
	"globe",
	"globe-hemisphere-east",
	"globe-hemisphere-west",
	"globe-simple",
	"globe-stand",
	"goggles",
	"goodreads-logo",
	"google-cardboard-logo",
	"google-chrome-logo",
	"google-drive-logo",
	"google-logo",
	"google-photos-logo",
	"google-play-logo",
	"google-podcasts-logo",
	"gradient",
	"graduation-cap",
	"grains",
	"grains-slash",
	"graph",
	"grid-four",
	"grid-nine",
	"guitar",
	"hamburger",
	"hammer",
	"hand",
	"hand-coins",
	"hand-eye",
	"hand-fist",
	"hand-grabbing",
	"hand-heart",
	"hand-palm",
	"hand-pointing",
	"hand-soap",
	"hand-swipe-left",
	"hand-swipe-right",
	"hand-tap",
	"hand-waving",
	"handbag",
	"handbag-simple",
	"hands-clapping",
	"hands-praying",
	"handshake",
	"hard-drive",
	"hard-drives",
	"hash",
	"hash-straight",
	"headlights",
	"headphones",
	"headset",
	"heart",
	"heart-break",
	"heart-half",
	"heart-straight",
	"heart-straight-break",
	"heartbeat",
	"hexagon",
	"high-heel",
	"highlighter-circle",
	"hoodie",
	"horse",
	"hourglass",
	"hourglass-high",
	"hourglass-low",
	"hourglass-medium",
	"hourglass-simple",
	"hourglass-simple-high",
	"hourglass-simple-low",
	"hourglass-simple-medium",
	"house",
	"house-line",
	"house-simple",
	"ice-cream",
	"identification-badge",
	"identification-card",
	"image",
	"image-square",
	"images",
	"images-square",
	"infinity",
	"info",
	"instagram-logo",
	"intersect",
	"intersect-square",
	"intersect-three",
	"jeep",
	"kanban",
	"key",
	"key-return",
	"keyboard",
	"keyhole",
	"knife",
	"ladder",
	"ladder-simple",
	"lamp",
	"laptop",
	"layout",
	"leaf",
	"lifebuoy",
	"lightbulb",
	"lightbulb-filament",
	"lighthouse",
	"lightning",
	"lightning-a",
	"lightning-slash",
	"line-segment",
	"line-segments",
	"link",
	"link-break",
	"link-simple",
	"link-simple-break",
	"link-simple-horizontal",
	"link-simple-horizontal-break",
	"linkedin-logo",
	"linux-logo",
	"list",
	"list-bullets",
	"list-checks",
	"list-dashes",
	"list-magnifying-glass",
	"list-numbers",
	"list-plus",
	"lock",
	"lock-key",
	"lock-key-open",
	"lock-laminated",
	"lock-laminated-open",
	"lock-open",
	"lock-simple",
	"lock-simple-open",
	"lockers",
	"magic-wand",
	"magnet",
	"magnet-straight",
	"magnifying-glass",
	"magnifying-glass-minus",
	"magnifying-glass-plus",
	"map-pin",
	"map-pin-line",
	"map-trifold",
	"marker-circle",
	"martini",
	"mask-happy",
	"mask-sad",
	"math-operations",
	"medal",
	"medal-military",
	"medium-logo",
	"megaphone",
	"megaphone-simple",
	"messenger-logo",
	"meta-logo",
	"metronome",
	"microphone",
	"microphone-slash",
	"microphone-stage",
	"microsoft-excel-logo",
	"microsoft-outlook-logo",
	"microsoft-powerpoint-logo",
	"microsoft-teams-logo",
	"microsoft-word-logo",
	"minus",
	"minus-circle",
	"minus-square",
	"money",
	"monitor",
	"monitor-play",
	"moon",
	"moon-stars",
	"moped",
	"moped-front",
	"mosque",
	"motorcycle",
	"mountains",
	"mouse",
	"mouse-simple",
	"music-note",
	"music-note-simple",
	"music-notes",
	"music-notes-plus",
	"music-notes-simple",
	"navigation-arrow",
	"needle",
	"newspaper",
	"newspaper-clipping",
	"notches",
	"note",
	"note-blank",
	"note-pencil",
	"notebook",
	"notepad",
	"notification",
	"notion-logo",
	"number-circle-eight",
	"number-circle-five",
	"number-circle-four",
	"number-circle-nine",
	"number-circle-one",
	"number-circle-seven",
	"number-circle-six",
	"number-circle-three",
	"number-circle-two",
	"number-circle-zero",
	"number-eight",
	"number-five",
	"number-four",
	"number-nine",
	"number-one",
	"number-seven",
	"number-six",
	"number-square-eight",
	"number-square-five",
	"number-square-four",
	"number-square-nine",
	"number-square-one",
	"number-square-seven",
	"number-square-six",
	"number-square-three",
	"number-square-two",
	"number-square-zero",
	"number-three",
	"number-two",
	"number-zero",
	"nut",
	"ny-times-logo",
	"octagon",
	"office-chair",
	"option",
	"orange-slice",
	"package",
	"paint-brush",
	"paint-brush-broad",
	"paint-brush-household",
	"paint-bucket",
	"paint-roller",
	"palette",
	"pants",
	"paper-plane",
	"paper-plane-right",
	"paper-plane-tilt",
	"paperclip",
	"paperclip-horizontal",
	"parachute",
	"paragraph",
	"parallelogram",
	"park",
	"password",
	"path",
	"patreon-logo",
	"pause",
	"pause-circle",
	"paw-print",
	"paypal-logo",
	"peace",
	"pen",
	"pen-nib",
	"pen-nib-straight",
	"pencil",
	"pencil-circle",
	"pencil-line",
	"pencil-simple",
	"pencil-simple-line",
	"pencil-simple-slash",
	"pencil-slash",
	"pentagram",
	"pepper",
	"percent",
	"person",
	"person-arms-spread",
	"person-simple",
	"person-simple-bike",
	"person-simple-run",
	"person-simple-throw",
	"person-simple-walk",
	"perspective",
	"phone",
	"phone-call",
	"phone-disconnect",
	"phone-incoming",
	"phone-outgoing",
	"phone-plus",
	"phone-slash",
	"phone-x",
	"phosphor-logo",
	"pi",
	"piano-keys",
	"picture-in-picture",
	"piggy-bank",
	"pill",
	"pinterest-logo",
	"pinwheel",
	"pizza",
	"placeholder",
	"planet",
	"plant",
	"play",
	"play-circle",
	"play-pause",
	"playlist",
	"plug",
	"plug-charging",
	"plugs",
	"plugs-connected",
	"plus",
	"plus-circle",
	"plus-minus",
	"plus-square",
	"poker-chip",
	"police-car",
	"polygon",
	"popcorn",
	"potted-plant",
	"power",
	"prescription",
	"presentation",
	"presentation-chart",
	"printer",
	"prohibit",
	"prohibit-inset",
	"projector-screen",
	"projector-screen-chart",
	"pulse",
	"push-pin",
	"push-pin-simple",
	"push-pin-simple-slash",
	"push-pin-slash",
	"puzzle-piece",
	"qr-code",
	"question",
	"queue",
	"quotes",
	"radical",
	"radio",
	"radio-button",
	"radioactive",
	"rainbow",
	"rainbow-cloud",
	"read-cv-logo",
	"receipt",
	"receipt-x",
	"record",
	"rectangle",
	"recycle",
	"reddit-logo",
	"repeat",
	"repeat-once",
	"rewind",
	"rewind-circle",
	"road-horizon",
	"robot",
	"rocket",
	"rocket-launch",
	"rows",
	"rss",
	"rss-simple",
	"rug",
	"ruler",
	"scales",
	"scan",
	"scissors",
	"scooter",
	"screencast",
	"scribble-loop",
	"scroll",
	"seal",
	"seal-check",
	"seal-question",
	"seal-warning",
	"selection",
	"selection-all",
	"selection-background",
	"selection-foreground",
	"selection-inverse",
	"selection-plus",
	"selection-slash",
	"shapes",
	"share",
	"share-fat",
	"share-network",
	"shield",
	"shield-check",
	"shield-checkered",
	"shield-chevron",
	"shield-plus",
	"shield-slash",
	"shield-star",
	"shield-warning",
	"shirt-folded",
	"shooting-star",
	"shopping-bag",
	"shopping-bag-open",
	"shopping-cart",
	"shopping-cart-simple",
	"shower",
	"shrimp",
	"shuffle",
	"shuffle-angular",
	"shuffle-simple",
	"sidebar",
	"sidebar-simple",
	"sigma",
	"sign-in",
	"sign-out",
	"signature",
	"signpost",
	"sim-card",
	"siren",
	"sketch-logo",
	"skip-back",
	"skip-back-circle",
	"skip-forward",
	"skip-forward-circle",
	"skull",
	"slack-logo",
	"sliders",
	"sliders-horizontal",
	"slideshow",
	"smiley",
	"smiley-angry",
	"smiley-blank",
	"smiley-meh",
	"smiley-nervous",
	"smiley-sad",
	"smiley-sticker",
	"smiley-wink",
	"smiley-x-eyes",
	"snapchat-logo",
	"sneaker",
	"sneaker-move",
	"snowflake",
	"soccer-ball",
	"sort-ascending",
	"sort-descending",
	"soundcloud-logo",
	"spade",
	"sparkle",
	"speaker-hifi",
	"speaker-high",
	"speaker-low",
	"speaker-none",
	"speaker-simple-high",
	"speaker-simple-low",
	"speaker-simple-none",
	"speaker-simple-slash",
	"speaker-simple-x",
	"speaker-slash",
	"speaker-x",
	"spinner",
	"spinner-gap",
	"spiral",
	"split-horizontal",
	"split-vertical",
	"spotify-logo",
	"square",
	"square-half",
	"square-half-bottom",
	"square-logo",
	"square-split-horizontal",
	"square-split-vertical",
	"squares-four",
	"stack",
	"stack-overflow-logo",
	"stack-simple",
	"stairs",
	"stamp",
	"star",
	"star-and-crescent",
	"star-four",
	"star-half",
	"star-of-david",
	"steering-wheel",
	"steps",
	"stethoscope",
	"sticker",
	"stool",
	"stop",
	"stop-circle",
	"storefront",
	"strategy",
	"stripe-logo",
	"student",
	"subtitles",
	"subtract",
	"subtract-square",
	"suitcase",
	"suitcase-rolling",
	"suitcase-simple",
	"sun",
	"sun-dim",
	"sun-horizon",
	"sunglasses",
	"swap",
	"swatches",
	"swimming-pool",
	"sword",
	"synagogue",
	"syringe",
	"t-shirt",
	"table",
	"tabs",
	"tag",
	"tag-chevron",
	"tag-simple",
	"target",
	"taxi",
	"telegram-logo",
	"television",
	"television-simple",
	"tennis-ball",
	"tent",
	"terminal",
	"terminal-window",
	"test-tube",
	"text-a-underline",
	"text-aa",
	"text-align-center",
	"text-align-justify",
	"text-align-left",
	"text-align-right",
	"text-b",
	"text-columns",
	"text-h",
	"text-h-five",
	"text-h-four",
	"text-h-one",
	"text-h-six",
	"text-h-three",
	"text-h-two",
	"text-indent",
	"text-italic",
	"text-outdent",
	"text-strikethrough",
	"text-t",
	"text-underline",
	"textbox",
	"thermometer",
	"thermometer-cold",
	"thermometer-hot",
	"thermometer-simple",
	"thumbs-down",
	"thumbs-up",
	"ticket",
	"tidal-logo",
	"tiktok-logo",
	"timer",
	"tipi",
	"toggle-left",
	"toggle-right",
	"toilet",
	"toilet-paper",
	"toolbox",
	"tooth",
	"tote",
	"tote-simple",
	"trademark",
	"trademark-registered",
	"traffic-cone",
	"traffic-sign",
	"traffic-signal",
	"train",
	"train-regional",
	"train-simple",
	"tram",
	"translate",
	"trash",
	"trash-simple",
	"tray",
	"tree",
	"tree-evergreen",
	"tree-palm",
	"tree-structure",
	"trend-down",
	"trend-up",
	"triangle",
	"trophy",
	"truck",
	"twitch-logo",
	"twitter-logo",
	"umbrella",
	"umbrella-simple",
	"unite",
	"unite-square",
	"upload",
	"upload-simple",
	"usb",
	"user",
	"user-circle",
	"user-circle-gear",
	"user-circle-minus",
	"user-circle-plus",
	"user-focus",
	"user-gear",
	"user-list",
	"user-minus",
	"user-plus",
	"user-rectangle",
	"user-square",
	"user-switch",
	"users",
	"users-four",
	"users-three",
	"van",
	"vault",
	"vibrate",
	"video",
	"video-camera",
	"video-camera-slash",
	"vignette",
	"vinyl-record",
	"virtual-reality",
	"virus",
	"voicemail",
	"volleyball",
	"wall",
	"wallet",
	"warehouse",
	"warning",
	"warning-circle",
	"warning-diamond",
	"warning-octagon",
	"watch",
	"wave-sawtooth",
	"wave-sine",
	"wave-square",
	"wave-triangle",
	"waveform",
	"waves",
	"webcam",
	"webcam-slash",
	"webhooks-logo",
	"wechat-logo",
	"whatsapp-logo",
	"wheelchair",
	"wheelchair-motion",
	"wifi-high",
	"wifi-low",
	"wifi-medium",
	"wifi-none",
	"wifi-slash",
	"wifi-x",
	"wind",
	"windows-logo",
	"wine",
	"wrench",
	"x",
	"x-circle",
	"x-square",
	"yin-yang",
	"youtube-logo",
];
