export const RECENT_SEARCHES_DATA = [
	"Configuration Management Reports",
	"User Activity Log",
	"Performance Insights",
	"Access Control Setup",
	"Data Visualization",
	"System Health Check",
	"Security Reports",
	"User Analytics View",
	"Activity Monitoring",
	"Error Tracking Log",
];
