export const DATA = [
	{
		category: "Manage your account",
		description: "Morbi leo risus porta consectetur vestibulum ateros",
		icon: "ph-user-circle",
		links: [
			"How do I create an account?",
			"Can I change my account password?",
			"How do I update my profile information?",
			"Can I add or remove profile pictures?",
			"Is it possible to merge or link multiple accounts?",
			"What privacy settings are available for my account?",
			"How can I deactivate or delete my account?",
		],
	},
	{
		category: "Payment and billing",
		description: "Etiam consectetur duiut felis gravida laoreet",
		icon: "ph-currency-circle-dollar",
		links: [
			"How do I update my payment method?",
			"When will I be billed for my subscription?",
			"Can I change my subscription plan?",
			"What payment options are accepted?",
			"Is my payment information secure?",
			"How can I view my billing history?",
			"What should I do if my payment fails?",
		],
	},
	{
		category: "Legal and privacy",
		description: "Etiam sagittis neque finibus rutrum faucibus",
		icon: "ph-shield-star	",
		links: [
			"What is your privacy policy?",
			"How do you protect my personal information?",
			"Can I opt out of data collection and tracking?",
			"What are your terms of service and user agreements?",
			"How do I report a privacy concern or data breach?",
			"Is my data shared with third parties?",
			"What are my rights regarding my personal data?",
		],
	},
	{
		category: "Login and registration",
		description: "Duis scelerisque rhoncus semid gravida",
		icon: "ph-keyhole",
		links: [
			"How do I create a new account?",
			"What information is required for registration?",
			"I forgot my password. What should I do?",
			"Is there a minimum password strength requirement?",
			"How do I change my password?",
			"Can I update my registered email address?",
			"How can I delete my account if needed?",
		],
	},
	{
		category: "User interface and experience",
		description: "Curabitur sesem uturna volutpat vestibulum",
		icon: "ph-hand-pointing",
		links: [
			"How can I provide feedback on the user interface?",
			"What are some tips for improving user experience?",
			"Can I customize the interface to my preferences?",
			"What accessibility features are available?",
			"How do I navigate the user interface efficiently?",
			"Are there keyboard shortcuts for common actions?",
			"How often are user interface updates released?",
		],
	},
	{
		category: "Everything else",
		description: "Curabitur sesem uturna volutpat vestibulum",
		icon: "ph-dots-nine",
		links: [
			"How can I contact customer support?",
			"What are your business hours?",
			"Is there a mobile app available?",
			"How do I reset my security questions?",
			"Can I change my username or display name?",
			"What are the system requirements for your service?",
			"Is there a referral program or rewards program?",
		],
	},
];
