export const DATA = [
	{
		img: "1",
		title: "IT and Engineering",
		description: "Building the future with technology and innovation",
		count: 43,
		members: [
			{
				name: "Lizzie Wells",
				img: "6",
			},
			{
				name: "Susie Kelley",
				img: "7",
			},
			{
				name: "Dora Norris",
				img: "8",
			},
			{
				name: "Ola Briggs",
				img: "9",
			},
			{
				name: "Jesse Vega",
				img: "10",
			},
			{
				name: "Gabriel Richards",
				img: "11",
			},
		],
	},
	{
		img: "3",
		title: "UI and Design",
		description: "Crafting user experiences through exceptional design",
		count: 20,
		members: [
			{
				name: "Chase Chavez",
				img: "2",
			},
			{
				name: "Beatrice Tyler",
				img: "10",
			},
			{
				name: "Lura Willis",
				img: "11",
			},
			{
				name: "Arthur Stevens",
				img: "3",
			},
			{
				name: "Tillie Woods",
				img: "9",
			},
			{
				name: "Howard Holland",
				img: "4",
			},
		],
	},
	{
		img: "2",
		title: "Sales and Marketing",
		description: "Where strategy meets execution in sales and marketing",
		count: 32,
		members: [
			{
				name: "Lucile Lamb",
				img: "3",
			},
			{
				name: "Josie Day",
				img: "5",
			},
			{
				name: "Ivan Higgins",
				img: "1",
			},
			{
				name: "Katharine Gill",
				img: "2",
			},
			{
				name: "Amy Russell",
				img: "16",
			},
			{
				name: "Blake Allison",
				img: "15",
			},
		],
	},
	{
		img: "3",
		title: "Search Engine Optimization",
		description: "Boosting visibility and rankings with SEO strategies",
		count: 25,
		members: [
			{
				name: "Mina Daniels",
				img: "4",
			},
			{
				name: "Francis Duncan",
				img: "10",
			},
			{
				name: "Chad Brock",
				img: "9",
			},
			{
				name: "Raymond Morales",
				img: "14",
			},
			{
				name: "Nettie Floyd",
				img: "11",
			},
			{
				name: "Clarence Bass",
				img: "7",
			},
		],
	},
	{
		img: "4",
		title: "Human Resource Management",
		description: "Maximizing employee potential with HR expertise",
		count: 24,
		members: [
			{
				name: "Ruby Ramsey",
				img: "12",
			},
			{
				name: "Nettie Becker",
				img: "13",
			},
			{
				name: "Kathryn Spencer",
				img: "14",
			},
			{
				name: "Ronald Delgado",
				img: "15",
			},
			{
				name: "Stella Ramos",
				img: "16",
			},
			{
				name: "John Jefferson",
				img: "12",
			},
		],
	},
	{
		img: "5",
		title: "Research and Development",
		description: "Driving innovation through dedicated R&D efforts",
		count: 10,
		members: [
			{
				name: "Georgia Ruiz",
				img: "6",
			},
			{
				name: "Peter Warner",
				img: "7",
			},
			{
				name: "Virginia Hansen",
				img: "8",
			},
			{
				name: "Ora Hughes",
				img: "9",
			},
			{
				name: "Mathilda Ruiz",
				img: "10",
			},
		],
	},
	{
		img: "6",
		title: "AI and ML",
		description: "Leading the way in AI and ML advancements",
		count: 8,
		members: [
			{
				name: "Georgia Ruiz",
				img: "9",
			},
			{
				name: "Peter Warner",
				img: "10",
			},
			{
				name: "Virginia Hansen",
				img: "11",
			},
			{
				name: "Ora Hughes",
				img: "12",
			},
			{
				name: "Mathilda Ruiz",
				img: "13",
			},
		],
	},
	{
		img: "7",
		title: "Finance and Accounting",
		description: "Nulla labore excepteur suntelit nostrud labore aliqua",
		count: 4,
		members: [
			{
				name: "Georgia Ruiz",
				img: "6",
			},
			{
				name: "Peter Warner",
				img: "7",
			},
			{
				name: "Virginia Hansen",
				img: "8",
			},
			{
				name: "Ora Hughes",
				img: "9",
			},
		],
	},
	{
		img: "8",
		title: "Customer Support",
		description: "Connecting with customers through empathetic service",
		count: 4,
		members: [
			{
				name: "Georgia Ruiz",
				img: "15",
			},
			{
				name: "Peter Warner",
				img: "14",
			},
			{
				name: "Virginia Hansen",
				img: "13",
			},
			{
				name: "Ora Hughes",
				img: "12",
			},
		],
	},
];
